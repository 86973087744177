<template>
  <div>
    <div class="search_box">
      <div class="my_icons_btn">
        <div v-for="(tech, i) in options" :key="i">
          <div
            :class="
              tech.selected
                ? 'mr-2 tech_icon_btn_selected'
                : 'mr-2 tech_icon_btn'
            "
            @click="selectFilter(tech.value)"
            v-if="tech.show"
          >
            <img
              v-if="tech.value != 'All'"
              class="mr-2 neko_icon_btn"
              :src="getIcon(tech.value)"
            />

            <span>{{ tech.text }}</span>
            <div class="badge_tech">{{ tech.nb }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        filteredClientProjects.length == 0 &&
        filteredNekologicProject.length == 0 &&
        filteredInDevelopmentProject.length == 0 &&
        !this.loading
      "
    >
      <neko-separator :separator_text="$t('NO_RESULT_FOUND')" />
      <b-img
        class="NO_RESULT_FOUND"
        center
        data-aos="fade-up"
        fluid
        blank-color="#3C1C35"
        :src="require('../assets/NekoLogicGreetingCardFinal.png')"
      ></b-img>
    </div>

    <div v-if="isClientProjectAvailable">
      <div v-if="filteredClientProjects.length > 0">
        <neko-separator :separator_text="$t('CLIENT_PROJECTS')" />
        <b-container
          :class="
            filteredClientProjects.length <= 2 ? ' my-grid-2' : ' my-grid'
          "
        >
          <project-card
            v-for="project in filteredClientProjects"
            :image="project.project_image_url"
            :key="project.id"
            :name="project.project_name"
            :description="project.project_description"
            :platform="project.project_platform"
            :platformData="platforms"
            :projectData="project"
          ></project-card>
        </b-container>
      </div>
    </div>
    <div v-else>
      <neko-separator :separator_text="$t('CLIENT_PROJECTS')" />
      <b-container class="my-grid">
        <skeleton-card v-for="skeleton in 6" :key="skeleton" />
      </b-container>
    </div>

    <div v-if="isNekologicProjectAvailable">
      <div v-if="filteredNekologicProject.length > 0">
        <neko-separator :separator_text="$t('NEKO_LOGIC_PROJECTS')" />
        <b-container
          :class="
            filteredNekologicProject.length <= 2 ? ' my-grid-2' : ' my-grid'
          "
        >
          <project-card
            v-for="project in filteredNekologicProject"
            :image="project.project_image_url"
            :key="project.id"
            :name="project.project_name"
            :description="project.project_description"
            :platform="project.project_platform"
            :platformData="platforms"
            :projectData="project"
          ></project-card>
        </b-container>
      </div>
    </div>
    <div v-else>
      <neko-separator :separator_text="$t('NEKO_LOGIC_PROJECTS')" />
      <b-container class="my-grid">
        <skeleton-card v-for="skeleton in 6" :key="skeleton" />
      </b-container>
    </div>

    <div v-if="isInDevelopmentProjectAvailable">
      <div v-if="filteredInDevelopmentProject.length > 0">
        <neko-separator :separator_text="$t('IN_DEVELOPMENT')" />
        <b-container
          :class="
            filteredInDevelopmentProject.length <= 2 ? 'my-grid-2' : 'my-grid'
          "
        >
          <project-card
            v-for="project in filteredInDevelopmentProject"
            :image="project.project_image_url"
            :key="project.id"
            :name="project.project_name"
            :description="project.project_description"
            :platform="project.project_platform"
            :platformData="platforms"
            :projectData="project"
          ></project-card>
        </b-container>
      </div>
    </div>
    <div v-else></div>

    <div v-if="isOtherProjectAvailable">
      <neko-separator :separator_text="$t('Other')" />
      <b-container :class="otherProjects.length <= 2 ? 'my-grid-2' : 'my-grid'">
        <project-card
          v-for="project in otherProjects"
          :image="project.project_image_url"
          :key="project.id"
          :name="project.project_name"
          :description="project.project_description"
          :platform="project.project_platform"
          :platformData="platforms"
          :projectData="project"
        ></project-card>
      </b-container>
    </div>
    <div v-else></div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import ProjectCard from "@/components/ProjectCard.vue";
import NekoSeparator from "@/components/NekoSeparator.vue";
import SkeletonCard from "@/components/SkeletonCard.vue";
import proxy from "@/services/proxy";

export default {
  name: "Works",
  components: {
    "skeleton-card": SkeletonCard,
    "project-card": ProjectCard,
    "neko-separator": NekoSeparator,
  },

  mounted() {
    this.fetchData();
    this.track();
    this.queryFilter(this.$store.getters.getfilter);
  },
  watch: {
    dialogflowFilterSelected: function () {
      this.queryFilter(this.$store.getters.getfilter);
    },
  },
  computed: {
    dialogflowFilterSelected() {
      return this.$store.getters.getfilter;
    },
    filteredClientProjects() {
      return this.projectFilter(this.clientProjects);
    },
    filteredNekologicProject() {
      return this.projectFilter(this.internalProjects);
    },
    filteredInDevelopmentProject() {
      return this.projectFilter(this.inDevProjects);
    },
    isClientProjectAvailable() {
      return !(this.clientProjects.length === 0);
    },
    isNekologicProjectAvailable() {
      return !(this.internalProjects.length === 0);
    },
    isInDevelopmentProjectAvailable() {
      return !(this.inDevProjects.length === 0);
    },
    isOtherProjectAvailable() {
      return !(this.otherProjects.length === 0);
    },
  },
  data() {
    return {
      loading: false,
      clientProjects: [],
      internalProjects: [],
      inDevProjects: [],
      otherProjects: [],
      filtersSelected: [],
      platforms: [],
      options: [
        {text: "All", value: "All", selected: true, show: true, nb: 0},
        {text: "Android", value: 1, selected: false, show: false, nb: 0},
        {text: "iOS", value: 2, selected: false, show: false, nb: 0},
        {text: "Game", value: 10, selected: false, show: false, nb: 0},
        {text: "Switch", value: 9, selected: false, show: false, nb: 0},
        {text: "VR", value: 5, selected: false, show: false, nb: 0},
        {text: "AR", value: 3, selected: false, show: false, nb: 0},
        {text: "MR", value: 4, selected: false, show: false, nb: 0},
        {text: "Web", value: 6, selected: false, show: false, nb: 0},
        {
          text: "Installation",
          value: 11,
          selected: false,
          show: false,
          nb: 0,
        },
        {text: "Audio", value: 7, selected: false, show: false, nb: 0},
        {text: "Hardware", value: 12, selected: false, show: false, nb: 0},
        {text: "Consulting", value: 13, selected: false, show: false, nb: 0},
        {
          text: "Locational",
          value: 8,
          selected: false,
          show: false,
          nb: 0,
        },
        {text: "Backend", value: 14, selected: false, show: false, nb: 0},
      ],
    };
  },

  methods: {
    track() {
      this.$gtag.pageview({
        page_path: "/works",
      });
    },
    queryFilter(filter) {
      switch (filter) {
        case "ar":
          this.selectFilter(3);
          break;
        case "vr":
          this.selectFilter(5);
          break;
        case "web":
          this.selectFilter(6);
          break;
        case "audio":
          this.selectFilter(7);
          break;
        case "locational":
          this.selectFilter(8);
          break;
        case "game":
          this.selectFilter(10);
          break;
        case "installation":
          this.selectFilter(11);
          break;
        default:
          this.selectFilter("All");
      }
    },
    filterByCategory(projects, category) {
      let filteredProjects = [];
      projects.filter((value) => {
        if (value.project_category === category) {
          filteredProjects.push(value);
        }
      });

      return filteredProjects;
    },
    selectFilter(filter) {
      if (filter != "All") {
        let i = this.options.map((x) => x.value).indexOf(filter);
        for (let j = 0; j < this.options.length; j++) {
          this.options[j].selected = false;
          this.filtersSelected = [];
        }
        this.options[i].selected = !this.options[i].selected;
        if (this.options[i].selected == true) {
          this.filtersSelected.push(filter);
        } else {
          this.filtersSelected.splice(this.filtersSelected.indexOf(filter), 1);
        }
      } else {
        for (let i = 0; i < this.options.length; i++) {
          this.options[i].selected = false;
          this.filtersSelected = [];
        }
        this.options[0].selected = true;
      }
      this.resetAll();
    },
    resetAll() {
      let nbTrue = 0;
      for (let i = 1; i < this.options.length; i++) {
        if (this.options[i].selected == true) {
          nbTrue = nbTrue + 1;
        }
      }
      if (nbTrue > 0) {
        this.options[0].selected = false;
      } else {
        this.options[0].selected = true;
      }
    },
    projectFilter(projectsToFilter) {
      let projects = [];
      if (this.filtersSelected.length == 0) {
        projects = projectsToFilter;
      } else {
        for (let i = 0; i < projectsToFilter.length; i++) {
          if (
            this.filtersSelected.some((el) =>
              projectsToFilter[i].project_platform_id.includes(el)
            )
          )
            projects.push(projectsToFilter[i]);
        }
      }

      return projects;
    },
    async fetchData() {
      this.loading = true;
      proxy
        .get("/api/platforms/", {})
        .then((resp) => {
          const platforms = resp?.data || null;
          if (platforms === null) {
            throw Error("Error: No Data: " + JSON.stringify(resp));
          }

          if (platforms.length === 0) {
            throw Error("No Projects from API! Will revert to stub data");
          }
          this.platforms = platforms;
        })
        .catch((err) => {
          console.log("Error" + JSON.stringify(err));
          this.$gtag.exception({
            description: "Works page did not fetch data",
            fatal: false,
          });
        });
      proxy
        .get("/api/public/projects/", {})
        .then((resp) => {
          const projects = resp?.data || null;

          if (projects === null) {
            throw Error("Error: No Data: " + JSON.stringify(resp));
          }

          if (projects.length === 0) {
            throw Error("No Projects from API! Will revert to stub data");
          }

          this.internalProjects = this.filterByCategory(projects, "Nekologic");
          this.clientProjects = this.filterByCategory(projects, "Client");
          this.inDevProjects = this.filterByCategory(projects, "Indevelopment");
          this.otherProjects = this.filterByCategory(projects, "Other");
          this.setPlatformFilters(projects);
          this.options[0].nb = projects.length;
          this.setPlatformOrderByNumber();
          this.loading = false;
          this.queryFilter(this.$route.query.filter);
        })
        .catch((err) => {
          this.$gtag.exception({
            description: "Works page did not fetch data",
            fatal: false,
          });
          console.log(err);
          console.log("Error" + JSON.stringify(err));
        });
    },
    setPlatformFilters(projects) {
      for (let i = 0; i < projects.length; i++) {
        for (let j = 0; j < projects[i].project_platform_id.length; j++) {
          try {
            if (projects[i].project_platform_id[j] !== 15) {
              this.options[
                this.options
                  .map((x) => x.value)
                  .indexOf(projects[i].project_platform_id[j])
              ].show = true;
              this.options[
                this.options
                  .map((x) => x.value)
                  .indexOf(projects[i].project_platform_id[j])
              ].nb += 1;
            }
          } catch (err) {
            console.log(projects[i]);
            console.log(err);
          }
        }
      }
    },
    setPlatformOrderByNumber() {
      this.options.sort((a, b) => (a.nb < b.nb ? 1 : -1));
    },
    getIcon(icon) {
      switch (icon) {
        case 1:
          icon = require("../assets/icons-projects/Android.svg");
          break;
        case 2:
          icon = require("../assets/icons-projects/iOS.svg");
          break;
        case 3:
          icon = require("../assets/icons-projects/AR.svg");
          break;
        case 4:
          icon = require("../assets/icons-projects/MR.svg");
          break;
        case 5:
          icon = require("../assets/icons-projects/VR.svg");
          break;
        case 6:
          icon = require("../assets/icons-projects/Web.svg");
          break;
        case 7:
          icon = require("../assets/icons-projects/Audio.svg");
          break;
        case 8:
          icon = require("../assets/icons-projects/Locational.svg");
          break;
        case 9:
          icon = require("../assets/icons-projects/Switch.svg");
          break;
        case 10:
          icon = require("../assets/icons-projects/Game.svg");
          break;
        case 11:
          icon = require("../assets/icons-projects/Installation.svg");
          break;
        case 12:
          icon = require("../assets/icons-projects/Hardware.svg");
          break;
        case 13:
          icon = require("../assets/icons-projects/Consultation.svg");
          break;
        case 14:
          icon = require("../assets/icons-projects/Backend.svg");
          break;
        default:
          icon = "";
      }
      return icon;
    },
  },
};
</script>
<style>
.search_box {
  width: 75%;
  margin: 0px auto;
}
.my_icons_btn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 4px;
  justify-content: center;
}
.tech_icon_btn {
  position: relative;
  display: flex;
  user-select: none;
  line-height: 30px;
  cursor: pointer;
  font-size: 14px;
  padding: 2px 8px;
  margin: 2px 1px;
  color: #6c757d;
  text-transform: capitalize;
  text-align: center;
  border-radius: 2.25em;
  border: solid 4px transparent;
  background-color: white;
  transition: linear 0.2s;
}
.tech_icon_btn:hover {
  color: #f4b346;
}
.tech_icon_btn_selected {
  position: relative;
  display: flex;
  user-select: none;
  line-height: 30px;
  cursor: pointer;
  font-size: 14px;
  padding: 2px 8px;
  margin: 2px 1px;
  color: #f4b346;
  text-transform: capitalize;
  text-align: center;
  border: solid 4px #f4b346;
  border-radius: 2.25em;
  background-color: whitesmoke;
}
.card-padding {
  padding: 0px;
}
.neko_icon_btn {
  height: 30px;
  width: 30px;
}
.my-grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.2rem;
}

.my-grid-2 {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.2rem;
}
.badge_tech {
  position: absolute;
  right: -10px;
  top: -15px;
  height: 25px;
  width: 25px;
  background-color: #f4b346;
  border-radius: 100px;
  line-height: 26px;
  color: white;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .card-padding {
    padding: 10px;
    margin-top: 1.2rem;
  }
  .my-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .my-grid-2 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
.no_result_found {
  min-height: 600px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media only screen and (max-width: 1200px) {
  .my-grid-2 {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1.2rem;
  }
}
@media only screen and (max-width: 1024px) {
  .search_box {
    width: 95%;
    margin: 0px auto;
  }
  .no_result_found {
    min-height: 100px;
    width: 98%;
  }
  .neko_icon_btn {
    height: 20px;
    width: 20px;
  }
  .tech_icon_btn {
    font-size: 10px;
    line-height: 20px;
  }
  .tech_icon_btn_selected {
    line-height: 20px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 830px) {
  .my-grid {
    padding: 0px;
  }
}
@media only screen and (max-width: 370px) {
  .my-grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    margin: 0px;
  }
  .my-grid-2 {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}
</style>
